<template>
  <div
    id="loyout"
    class="screen-wrapper"
    :style="{ 'margin-top': fromUser == 'guizhou' ? '80px' : '' }"
  >
    <div class="screen" id="screen">
      <!-- 页面头部 -->
      <header class="my-header">
        <!-- <Weather style="position: absolute; left: 20px; top: 20px;"></Weather> -->
        <div class="my-title flex justify-center align-center">
          <div class="img-bg">
            <div class="flex justify-center align-center">
              <div
                class="flex justify-end align-center text-xsss flex1"
                style="padding-bottom: 20px;"
              >
                <div v-if="!isChongHuanDian" class="top-search">
                  <input
                    v-model="licensePlate"
                    class="inp"
                    type="text"
                    placeholder="车牌搜索"
                    confirm-type="search"
                    @keyup.enter="licensePlateSearch"
                  />
                  <!-- <img @click="licensePlateSearch" class="img" src="@/assets/images/comm/search.png" /> -->
                </div>
                <span
                  class="flex align-center"
                  @click="showNationDialogFunc()"
                  style="margin-right: 20px"
                >
                  全国<img
                    class="clickNationwideModal"
                    src="@/assets/images/index/click.png"
                  />
                </span>
                <span
                  v-if="!isChongHuanDian"
                  class="flex align-center"
                  @click="showVehicleFunc()"
                >
                  {{ vehicleObj.label || "全部车型"
                  }}<img
                    class="clickNationwideModal"
                    src="@/assets/images/index/click.png"
                  />
                </span>
              </div>
              <div class="top-title">
                <!-- 移动能源光储充换云一体化大屏 -->
              </div>
              <div
                class="flex justify-end align-center text-xsss flex1 DS-Digital-Bold"
                style="padding-right: 40px"
              >
                <!-- {{ nowDate + ' ' + nowTime }} -->
                <div
                  :class="showMap ? 'show2dBtn show3dBtn' : 'show2dBtn'"
                  @click="clickMapBtn"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- 页面主体路由 -->
      <div id="expositionMain">
        <transition name="main" mode="out-in">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </div>
      <!-- 页面底部 -->
      <!-- <myFooter /> -->
      <!-- 数字人 -->
      <!-- <div class="humanrobat">
				<img class="robat-img" :src="humanRobatImg" alt="">
				<img class="robat-bg " :src="require('@/assets/images/exposition/humanrobat-bg.png')" alt="">
				<div v-if="robatMP3">
					<audio autoplay id="humanrobatAudio" :src="robatMP3" />
				</div>

				<div v-if="textPopShow" class="text-pop">
					<span class="pop-close" @click="textPopShow = false">+</span>
					<div class="pop-box">
						<div :style="{'top': textTop + 'px'}">
							<p v-if="isChongHuanDian">场站为出租车、网约车、物流车、渣土车、矿卡车、重卡车等提供可充可换的全方面灵活补能方式，覆盖300多座城市，兼容市场主流的12种换电站站型、9款充电桩品牌、3个充电平台品牌，满足新能源车主多样化多场景出行补能需求。数字平台打通充换电系统、车辆以及电池的全链路流程，提供全面的数智运营运维和智能服务，促进新能源汽车充换电高质量发展。</p>
							<p v-else>数字平台提供新能源汽车管控的数字化解决方案，实时采集新能源汽车全方位数据，聚焦交通、双碳、安全、能源四大核心指标，实现新能源汽车运行立体化监测、智能化研判、全方位预警、精准化管控、能源交通融合治理等技术难题，促进城市零碳能源交通高效融合发展。</p>
						</div>
					</div>
				</div>
			</div> -->

      <!-- 城市弹窗 -->
      <div class="nationDialog" v-show="showNationDialog">
        <div class="dialog flex flex-direction">
          <div
            class="diaTop flex justify-between align-center pointer"
            @click="jumpToNationwide"
          >
            <div class="title">全国</div>
            <div class="close pointer" @click="showNationDialog = false">
              <img src="@/assets/images/index/close.png" />
            </div>
          </div>
          <div class="diaCener">
            <div class="province">
              <div class="title">省份</div>
              <div class="provItem">
                <p
                  class="text"
                  :class="{ select: curProvince == index }"
                  v-for="(item, index) in provinceStationInfo"
                  :key="index"
                  @click="clickProvince(item, index)"
                >
                  {{ item.provinceName | formatterProvince }}
                </p>
              </div>
            </div>
            <div class="province" v-show="showCity && cityStationInfo.length">
              <div class="title">城市</div>
              <div class="provItem">
                <p
                  class="text"
                  :class="{ select: curCity == index }"
                  v-for="(item, index) in cityStationInfo"
                  :key="index"
                  @click="clickCity(item, index)"
                >
                  {{ item.cityName }}
                </p>
              </div>
            </div>
            <div
              class="province"
              v-show="showDistrict && districtStationInfo.length"
            >
              <div class="title">区域</div>
              <div class="provItem">
                <p
                  class="text"
                  :class="{ select: curDistrict == index }"
                  v-for="(item, index) in districtStationInfo"
                  :key="index"
                  @click="clickDistrict(item, index)"
                >
                  {{ item.districtName }}
                </p>
              </div>
            </div>
          </div>

          <div class="diaBottom flex">
            <button class="confirm pointer" @click="confirm">确定</button>
          </div>
        </div>
      </div>
      <!-- 车辆弹窗 -->
      <div
        class="nationDialog"
        v-show="showVehicleDialog"
        style="height: 200px"
      >
        <div class="dialog flex flex-direction">
          <div class="diaTop flex justify-between align-center pointer">
            <p
              class="text"
              v-for="(item, index) in vehicleList"
              :key="'vehicleList' + index"
              @click="clickVehicleList(item)"
            >
              {{ item.label }}
            </p>
            <div class="close pointer" @click="showVehicleDialog = false">
              <img src="@/assets/images/index/close.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="fullScreen flex">
				<img v-show="showFullScreen" src="@/assets/images/index/fullscreen.png" @click="clickFullScreen">
				<img v-show="!showFullScreen" src="@/assets/images/index/fullscreen-exit.png" @click="clickExit">
			</div> -->
    </div>
  </div>
</template>

<script>
// import Weather from '@/views/mobile/components/Weather.vue'
import { formatter } from "@/utils/index.js";
import { indexCenter } from "@/api/indexCenter";
import { dataInterface } from "@/api/dataInterfaceApi";
// import MyFooter from './exposition/components/myFooter.vue'
// 充换电地址
let whileList = [
  "changingElectricity",
  "changingElectricityMerge",
  "powerStation",
  "batteryCompartment",
  "batteryInfo",
];

export default {
  components: {
    // Weather,
    // MyFooter
  },
  data() {
    return {
      fromUser: sessionStorage.getItem("fromUser"),
      licensePlate: "",
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期
      provinceStationInfo: [], //全国站点
      isProvinceAjax: false,
      curProvince: -1, //当前站点
      curDistrict: -1, //当前大区
      curCity: -1, //当前城市
      showCity: false, //是否显示城市
      showDistrict: false, //是否显示大区
      cityStationInfo: [], //省级站点
      districtStationInfo: [], //市级站点
      curDistrictInfo: {
        district: "110101",
      }, //当前大区信息
      showNationDialog: false, //显示全国弹框
      showFullScreen: true, //全屏按钮

      vehicleObj: JSON.parse(
        localStorage.getItem("vehicle::vehicleTypw") || "{}"
      ),
      adcode: "",
      showVehicleDialog: false, // 全部车型
      vehicleList: [
        {
          value: "0",
          label: "全部车型",
        },
        {
          value: "1",
          label: "乘用车",
        },
        {
          value: "2",
          label: "商用车",
        },
      ],
      humanRobatImg: require("@/assets/images/exposition/humanrobat.png"),
      robatMP3: null,
      countdownInterval: null,
      textPopShow: false,
      textTop: 0,
      textInterval: null,
      cityName: "",
      changeMapBtn: false,
      showMap: localStorage.getItem("showMap") || false,
    };
  },
  computed: {
    isChongHuanDian() {
      let { name } = this.$route;
      return whileList.includes(name); // 是否是充换电地址
    },
  },
  created() {
    this.showMap = localStorage.getItem("showMap") == "true" ? true : false;
  },
  methods: {
    //数据大屏自适应函数
    handleScreenAuto() {
      const designDraftWidth = 3840; //设计稿的宽度
      const designDraftHeight = 1080; //设计稿的高度
      //根据屏幕的变化适配的比例
      const scale =
        document.documentElement.clientWidth /
          document.documentElement.clientHeight <
        designDraftWidth / designDraftHeight
          ? document.documentElement.clientWidth / designDraftWidth
          : document.documentElement.clientHeight / designDraftHeight;
      //缩放比例

      console.log("safasdfadfasdfadsf", scale);
      // document.querySelector('#screen').style.transform = `scale(${scale}) translate(-50%)`;
      let scaleY =
        parseFloat(localStorage.getItem("bigScreen::scaleY")) || 1;
      if (this.fromUser == "guizhou") {
        document.querySelector(
          "#screen"
        ).style.transform = `scale(${scale}, ${scale * 1.6}) translate(-50%)`;
      } else {
        document.querySelector(
          "#screen"
        ).style.transform = `scale(${scale}, ${scale *
          scaleY}) translate(-50%)`;
      }
      // document.querySelector('#screen').style.transform = `scale(${scale}, ${scale * 0.99}) translate(-50%)`;
    },
    clicMapChange() {
      console.log("mapChange", this.$refs);
    },
    //全屏
    clickFullScreen() {
      const docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      this.showFullScreen = !this.showFullScreen;
    },
    //点击退出全屏
    clickExit() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      this.showFullScreen = !this.showFullScreen;
    },
    //清空数据
    clearData() {
      this.districtStationInfo.length = 0;
      this.cityStationInfo.length = 0;
      this.curProvince = -1;
      this.curDistrict = -1;
      this.curCity = -1;
    },
    showNationDialogFunc() {
      this.selectCountryStationInfo();
      this.showNationDialog = true;
      this.showVehicleDialog = false;
    },
    showVehicleFunc() {
      // this.selectCountryStationInfo();
      this.showVehicleDialog = true;
      this.showNationDialog = false;
    },
    clickVehicleList(item) {
      this.showVehicleDialog = false;
      localStorage.setItem("vehicle::vehicleTypw", JSON.stringify(item));
      this.vehicleObj = item;
      this.$router.push({
        path: "/vehicles",
        query: {
          vehicle: item.value,
        },
      });
    },
    //跳转到全国
    jumpToNationwide() {
      this.showNationDialog = false; //关闭弹框
      this.$router.push({
        path: this.isChongHuanDian ? "changingElectricity" : "/vehicles",
        query: {},
      });
    },
    //点击省级 区分 直辖市和其他
    clickProvince(item, index) {
      if (this.isProvinceAjax) return;
      this.isProvinceAjax = true;
      console.log(item, "选择省份");
      this.cityName = item.provinceName;
      this.clearData();
      let adcode = parseInt(item.province);
      this.adcode = adcode;
      let updateParams = {};
      if (this.curProvince == index) {
        return;
      }
      this.curProvince = index;
      if ([110000, 120000, 500000, 310000].includes(adcode)) {
        updateParams = {
          city: adcode.toString().substring(0, 2) + "0100",
        };
        this.selectCityStationInfo(updateParams);
      } else {
        updateParams = {
          province: adcode,
        };
        this.selectProvinceStationInfo(updateParams);
      }
    },
    clickCity(item, index) {
      if (this.isProvinceAjax) return;
      this.isProvinceAjax = true;
      console.log(item, "选择城市");
      this.cityName = item.cityName;
      this.curDistrict = -1;
      if (this.curCity != index) {
        this.curCity = index;
        this.adcode = item.city;
        this.selectCityStationInfo({
          city: item.city,
        });
      }
    },
    clickDistrict(item, index) {
      if (this.curDistrict != index) {
        console.log(item, "选择区域");
        this.curDistrict = index;
        this.curDistrictInfo = item;
        this.cityName = item.districtName;
      }
    },
    //确定按钮
    confirm() {
      if (
        this.curProvince == -1 &&
        this.curCity == -1 &&
        this.curDistrict == -1
      ) {
        console.log("您还没有选择省份");
        return;
      }

      if (
        this.curProvince > -1 &&
        this.curCity == -1 &&
        this.curDistrict == -1
      ) {
        console.log("选择省份");
        this.showNationDialog = false; //关闭弹框
        this.$router.push({
          path: this.isChongHuanDian
            ? "changingElectricity"
            : "/newVehiclesProvince",
          query: {
            province: this.adcode,
            name: this.cityName,
          },
        });
        return;
      }

      if (
        this.curProvince > -1 &&
        this.curCity > -1 &&
        this.curDistrict == -1
      ) {
        console.log("选择城市");
        this.showNationDialog = false; //关闭弹框
        this.$router.push({
          path: this.isChongHuanDian
            ? "changingElectricity"
            : "/newVehiclesProvince",
          query: {
            city: this.adcode,
            name: this.cityName,
          },
        });
        return;
      }

      if (this.curProvince > -1 && this.curCity > -1 && this.curDistrict > -1) {
        console.log("选择区");
        this.showNationDialog = false; //关闭弹框
        this.$router.push({
          path: this.isChongHuanDian
            ? "changingElectricity"
            : "/newVehicleControl",
          query: {
            city: this.curDistrictInfo.district,
            name: this.cityName,
          },
        });
        return;
      }
    },
    //获取全国站点数据
    selectCountryStationInfo() {
      // let _this = this;
      indexCenter
        .selectCountryStationInfo()
        .then((res) => {
          if (!res) return;
          console.log(res, "点击全国按钮");
          this.provinceStationInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取省级站点数据
    selectProvinceStationInfo(updateParams) {
      // let _this = this;
      //获取省的站点信息
      indexCenter
        .selectProvinceStationInfo(updateParams)
        .then((res) => {
          this.isProvinceAjax = false;
          if (!res) return;
          this.cityStationInfo = res;
          if (res.length > 0) {
            this.showCity = true;
            this.showDistrict = false;
          } else {
            this.showCity = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取区级站点数据
    selectCityStationInfo(updateParams) {
      // let _this = this;
      //获取省的站点信息
      indexCenter
        .selectCityStationInfo(updateParams)
        .then((res) => {
          this.isProvinceAjax = false;
          if (!res) return;
          this.districtStationInfo = res;
          if (res.length > 0) {
            this.showDistrict = true;
          } else {
            this.showDistrict = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    currentTime() {
      if (this.countdownInterval) clearInterval(this.countdownInterval);
      this.countdownInterval = setInterval(this.getDate, 60);
    },
    getDate: function() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let week = new Date().getDay();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      if (week == 1) {
        this.nowWeek = "星期一";
      } else if (week == 2) {
        this.nowWeek = "星期二";
      } else if (week == 3) {
        this.nowWeek = "星期三";
      } else if (week == 4) {
        this.nowWeek = "星期四";
      } else if (week == 5) {
        this.nowWeek = "星期五";
      } else if (week == 6) {
        this.nowWeek = "星期六";
      } else {
        this.nowWeek = "星期日";
      }
      _this.nowTime = hh + ":" + mf;
      // _this.nowDate = yy + "年" + mm + "月" + dd + "日";
      _this.nowDate = yy + "-" + mm + "-" + dd + "-";
    },
    // 车辆搜索
    licensePlateSearch() {
      console.log(this.licensePlate);
      if (!this.licensePlate) return;
      let params = {
        enCode: "per_vehicle-车牌搜索接口",
        "@LICENSE_PLATE": this.licensePlate,
      };
      dataInterface.dataFaceApi(params).then((res) => {
        // console.log('adfaf', res)
        if (!res || !res[0] || !res[0].vehicle_vin) return;
        let vehicle_vin = res[0].vehicle_vin;
        localStorage.setItem("newVehicleNum", vehicle_vin);
        this.$router.push({
          path: "/singleVehicle",
          query: {},
        });
      });
    },
    // 数字人语音播报
    humanRobatPaly() {
      let name = this.$route.name;
      let that = this;
      if (name === "powerStation") {
        // 换电站页面
        that.robatMP3 = null;
        that.textPopShow = false;
        setTimeout(() => {
          that.humanRobatImg = require("@/assets/images/exposition/humanrobat-2.png");
          // that.robatMP3 = require('@/assets/images/mp3/b.mp3')
          setTimeout(() => {
            let audio = document.querySelector("#humanrobatAudio");
            // audio.play()
            audio.onended = () => {
              that.robatMP3 = null;
              that.humanRobatImg = require("@/assets/images/exposition/humanrobat.png");
              that.textPopShow = false;
            };
          }, 10);
          that.textPopShow = true;
          that.textTop = 0;
          setTimeout(() => {
            that.textInterval = setInterval(() => {
              that.textTop += -6;
              if (that.textTop <= -160) {
                clearInterval(that.textInterval);
              }
            }, 1000);
          }, 8000);
        }, 5000);
      } else if (name === "newVehicleControl") {
        // 多车页面
        that.robatMP3 = null;
        that.textPopShow = false;
        setTimeout(() => {
          that.humanRobatImg = require("@/assets/images/exposition/humanrobat-3.png");
          // that.robatMP3 = require('@/assets/images/mp3/c.mp3')
          setTimeout(() => {
            let audio = document.querySelector("#humanrobatAudio");
            // audio.play()
            audio.onended = () => {
              that.robatMP3 = null;
              that.humanRobatImg = require("@/assets/images/exposition/humanrobat.png");
              that.textPopShow = false;
            };
          }, 10);
          that.textPopShow = true;
          that.textTop = 0;
          setTimeout(() => {
            that.textInterval = setInterval(() => {
              that.textTop += -5;
              if (that.textTop <= -110) {
                clearInterval(that.textInterval);
              }
            }, 1000);
          }, 7000);
        }, 5000);
      }
    },
    clickMapBtn() {
      // this.changeMapBtn = !this.changeMapBtn;
      this.showMap = localStorage.getItem("showMap") == "true" ? true : false;
      this.showMap = !this.showMap;

      localStorage.setItem("showMap", this.showMap);
      this.$bus.$emit("showMap");
      // localStorage.setItem("changeMapBtn", this.changeMapBtn);
      console.log("切换3D");
    },
  },
  filters: {
    formatterProvince(name) {
      if (!name) return "";
      console.log(name);
      return formatter(name);
    },
  },
  mounted() {
    let that = this;
    // 浏览器 title
    document.title = "移动能源光储充换车云一体化大屏";

    that.currentTime();
    //初始化自适应  ----在刚显示的时候就开始适配一次
    that.handleScreenAuto();
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    window.onresize = function() {
      that.handleScreenAuto();
    };
    let { query } = this.$route;
    let { fromUser, scaleY } = query;
    fromUser ? sessionStorage.setItem("fromUser", fromUser) : "";
    scaleY ? localStorage.setItem("bigScreen::scaleY", scaleY) : "";
    // 数字人语音播报
    this.humanRobatPaly();
  },
  watch: {
    $route: "humanRobatPaly",
    // $route: {
    // 	handler: (val) => {
    // 		console.log('this.$route', val)
    // 		// // 数字人语音播报
    // 		// this.humanRobatPaly(val.name)
    // 		this.robatMP3 = null
    // 	},
    // 	deep: true
    // }
  },
  // 销毁定时器
  beforeDestroy: function() {
    // 在Vue实例销毁前，清除时间定时器
    clearInterval(this.countdownInterval);
    // 数字人播报
    clearInterval(this.textInterval);
    this.robatMP3 = null;
    // if (this.getDate) {
    // 	clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
    // }
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/scss/pc.scss";
@import "@/views/exposition/components/scss/humanrobat.scss";
@font-face {
  font-family: "DS-Digital-Bold";
  src: url("~@/assets/font/DS-Digital-Bold.ttf");
}

.DS-Digital-Bold {
  font-family: "DS-Digital-Bold";
}

body {
  background: #000 !important;
}
.screen-wrapper {
  height: 100%;
  width: 100%;
  .screen {
    overflow: hidden;
    display: inline-block;
    width: 3840px;
    height: 1650px;
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
  }
}
.my-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 129px;
  z-index: 10;
}
#expositionMain {
  height: 100%;
  // padding-top: 129px;
  // box-sizing: border-box;
}

.my-title {
  width: 100%;
  height: 129px;

  .img-bg {
    width: 100%;
    height: 100%;
    background: url("~@/assets/images/exposition/top-title1.png") no-repeat
      center center;
    background-size: 100% 100%;

    .leftBox {
      width: 300px;
      margin-right: 180px;
    }

    .bigtitle {
      line-height: 110px;
      font-family: "PuHuiTi Heavy";
    }

    .rightBox {
      width: 300px;
      margin-left: 180px;
    }
    .top-title {
      width: 1000px;
      line-height: 98px;
      text-align: center;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 4px;
      color: #ffffff;

      // width: 887px;
      // height: 81px;
      margin: 70px 220px 0;
      // background: url('~@/assets/images/exposition/top-title-text.png') no-repeat center center;
      // background-size: 100% 100%;
    }
  }
  .clickNationwideModal {
    width: 16px;
    height: 14px;
    margin-left: 8px;
    margin-top: -2px;
  }
}

// 全国省市区-弹框
.nationDialog {
  width: 900px;
  height: 680px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  .title {
    font-size: 24px;
  }

  .text {
    font-size: 20px;

    &.select {
      background: rgba(42, 255, 251, 0.23);
      border: 1px solid #2afffb;
      border-radius: 30px;
    }
  }

  .close {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .dialog {
    height: 100%;
    padding: 45px 30px 30px 80px;
    background: linear-gradient(
      180deg,
      rgba(35, 94, 92, 0.8) 0%,
      rgba(36, 98, 96, 0.8) 100%
    );

    .diaTop {
      height: 42px;
      margin-bottom: 46px;
    }

    .diaCener {
      flex: 1;
      overflow-y: scroll;

      .province {
        margin-bottom: 30px;
      }

      .provItem {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        grid-row-gap: 16px;
        text-align: center;
      }
    }

    .diaBottom {
      button {
        width: 98px;
        height: 40px;
        border-radius: 8px;
        margin-left: auto;
        font-size: 18px;
        font-weight: bold;
        background: linear-gradient(
          180deg,
          rgba(10, 98, 96, 0.6) 0%,
          rgba(1, 177, 169, 0.6) 100%
        );
        border-radius: 8px;
      }
    }
  }
}

.fullScreen {
  position: fixed;
  top: 30px;
  right: 30px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}

.threeDChange {
  position: absolute;
  top: 30px;
  right: 70px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }
}

.top-search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  .inp {
    background: none;
    border: none;
    text-align: right;
    padding-right: 5px;
    color: #fff;
  }
  .img {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}

.show2dBtn {
  background: url("~@/assets/images/index/3d_1.png");
  background-size: 100% 100%;
  display: block;
  position: fixed;
  width: 20px;
  height: 20px;
  margin-right: 40px;
  top: 20px;
  right: 60px;
  cursor: pointer;
  &.show3dBtn {
    background: url("~@/assets/images/index/2d_1.png");
    background-size: 100% 100%;
  }
}
</style>
